import React, { useContext, useState } from 'react';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';

import { AuthContext } from '../contexts/Auth';
import AppAuthMode from '../AppAuthMode';

/**
 * React component for rendering a SignIn form
 *
 * @param {function} changeAuthMode callback to update the app auth mode,
 *                   must accept AppAuthState enum
 * @param {function} setEmailNeedsVerified callback to update the email in
 *                   the parent state in the event of signIn errors
 * @returns React component
 */
const SignIn = ({ changeAuthMode, setEmailNeedsVerified }) => {
  const { signIn, resendSignup } = useContext(AuthContext);
  const [password, setPassword] = useState();
  const [email, setEmail] = useState();

  const handleSignInError = (error) => {
    console.error('signIn error: ', error);
    switch (error.code) {
      case 'UserNotConfirmedException':
        resendSignup(email);
        setEmailNeedsVerified(email);
        changeAuthMode(AppAuthMode.VERIFY);
        break;
      default:
        console.error(error);
    }
  };

  const handleSignInClicked = (event) => {
    event.preventDefault();
    return signIn(email, password)
      .then(() => navigate('/'))
      .catch(handleSignInError);
  };

  const handleEmailChanged = (event) => {
    const email = event.target.value;
    setEmail(email);
  };

  const handlePasswordChanged = (event) => {
    const password = event.target.value;
    setPassword(password);
  };

  return (
    <div label="SignIn" className="sign-in-form">
      <form>
        <label htmlFor="email">
          <p>email</p>
          <input
            type="text"
            onChange={handleEmailChanged}
            id="email"
          />
        </label>
        <label htmlFor="password">
          <p>password</p>
          <input
            type="password"
            onChange={handlePasswordChanged}
            id="password"
          />
        </label>
        <div>
          <button type="submit" onClick={handleSignInClicked}>
            Sign In
          </button>
        </div>
      </form>
    </div>
  );
};

SignIn.propTypes = {
  changeAuthMode: PropTypes.func,
  setEmailNeedsVerified: PropTypes.func,
};

SignIn.defaultProps = {
  changeAuthMode: undefined,
  setEmailNeedsVerified: undefined,
};

export default SignIn;
