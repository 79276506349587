/**
 *  @typedef {import("../contexts/Auth/CosmicPumaUser").default} CosmicPumaUser
 */

import React, { useContext } from 'react';

import { AuthContext } from '../contexts/Auth';

/**
 * React component to render when the user is already signed in
 * @returns React component
 */
const SignedIn = () => {
  const { user } = useContext(AuthContext);

  return (
    <>
      <h1>You are logged in!</h1>
      <ul>
        <li>
          Email:
          {user.email}
        </li>
        <li>
          Username:
          {user.username}
        </li>
        <li>
          UserId:
          {user.userId}
        </li>
      </ul>
    </>
  );
};

export default SignedIn;
